import React, { useState } from 'react';
import Header from './Header';
import { quotesData } from './quotes.js';
import './style.css';

export default function App() {
  const defaultQuote = quotesData[2];
  const [quote, setQuote] = useState(defaultQuote);
  const [answer, setAnswer] = useState('');
  const [result, setResult] = useState(null);

  const handleGetQuote = () => {
    const randomItem = Math.floor(Math.random() * quotesData.length);
    setQuote(quotesData[randomItem]);
    setResult(null);
  };

  const handleCheckAnswer = () => {
    if (answer.trimEnd().toLowerCase() === quote.answer.toLowerCase()) {
      setResult(`${quote.author} is correct!`);
    } else {
      setResult('Not quite!');
    }
  };

  const handleEnterKeypress = (e) => {
    if (e.key === 'Enter') {
      handleCheckAnswer();
    }
  };

  const emojiStrings = quote.quoteText.split(' ');

  return (
    <>
      <Header />
      <h1>Lit Emojis</h1>
      <div>
        <div className="question">
          <h3>
            The following string of emojis represents a quote from a classic
            text or a celebrated work of literature.
          </h3>

          <h3>Can you guess the author?</h3>
        </div>

        <div>
          {emojiStrings.map((item) => {
            return (
              <p className="emojiText" onClick={handleGetQuote}>
                {item}
              </p>
            );
          })}
        </div>

        {!result && (
          <div className="answerBox">
            <input
              id="author"
              onChange={(e) => setAnswer(e.target.value)}
              type="text"
              onKeyUp={handleEnterKeypress}
            />
            <button type="button" onClick={handleCheckAnswer}>
              Check
            </button>
          </div>
        )}

        {!!result && (
          <div class="result">
            <p>{result}</p>
            <span></span>
            <button onClick={handleGetQuote}>Try another</button>
          </div>
        )}

        {!result && (
          <p className="hint">
            Answers should be one word only, <wbr /> e.g. "Sade" <wbr /> instead
            of
            <wbr /> "Marquis de Sade".
          </p>
        )}
      </div>
    </>
  );
}
