import React from 'react';

const Header = () => {
  return (
    <header>
      <nav>
        <ul>
          <li>
            <a>afang.dev</a>
          </li>
          {/* <li>
            <a>Home</a>
          </li> */}
          <li class="hide-on-xs">
            <a>Projects</a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
