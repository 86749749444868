export const quotesData = [
  {
    id: 'times',
    quoteText: '👍⏱️👎⏱️ 🗓️🧠🗓️🤡',
    author: 'Charles Dickens',
    answer: 'Dickens',
  },
  {
    id: 'tyger',
    quoteText: '🐯🐯🔥😎🌲🌚 👻✋👁️🖼️😱🪞',
    author: 'William Blake',
    answer: 'Blake',
  },
  {
    id: 'stage',
    quoteText: '🌎🎭 🧑‍🤝‍🧑👯👥',
    author: 'Shakespeare',
    answer: 'Shakespeare',
  },
  {
    id: 'chains',
    quoteText: '👨👶🆓 👨🌍⛓️',
    author: 'Rousseau',
    answer: 'Rousseau',
  },
  {
    id: 'verses',
    quoteText: '📖⤵️🌳 🍞🏺🙎',
    author: 'Omar Khayyam',
    answer: 'Khayyam',
  },
  {
    id: 'sand',
    quoteText: '👀🌎🏖️👼🌼 ✊♾️✋⏳⏲️',
    author: 'William Blake',
    answer: 'Blake',
  },
  {
    id: 'heart',
    quoteText: '🫁😮‍💨👂🎖️❤️ 😶😶😶',
    author: 'Sylvia Plath',
    answer: 'Plath',
  },
    {
    id: 'insect',
    quoteText: '👨🛏️🌞 👨🔄🪳',
    author: 'Franz Kafka',
    answer: 'Kafka',
  },
    {
    id: 'pig',
    quoteText: '🐗🐴🐶👀 🐷👀👨👀🐷👀👨 🤔🤔🤔',
    author: 'George Orwell',
    answer: 'Orwell',
  },
];
